<template>
  <div id="app">
        <div class="spinner-box" v-show="spinerInProgerss">
      <div class="spinner"></div>
    </div>
          <!-- <news></news> -->
        <router-view></router-view>
        
  </div> 
</template>

<script>

import translate from '../translation/translate'
// import news from '.././src/components/news.vue'

export default {
  name: 'App',
  data() {
    return {
      screenWith: window.innerWidth,
      translation: translate,
      spinerInProgers: false
    }
  },
    created() {
    this.spinerInProgerss = true
  },
  mounted() {
    if(this.screenWith > 768) this.$bvModal.show('bv-modal-example')
    this.spinerInProgerss = false
  },
  
}
</script>

<style>


</style>
